import PageContainer from "./PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import species_img from "../imgs/wood_square.png";
import origin_img from "../imgs/_WW1110164.jpg";
import understandRiskImage1 from "../imgs/_WW14401.jpg";
import understandRiskImage2 from "../imgs/_WW184538.jpg";
import understandRiskImage3 from "../imgs/_WW173030.jpg";
import manageRiskImage from "../imgs/Medium_WW172979.jpg";

function ContentPage() {
  let { slug } = useParams();
  let navigate = useNavigate();
  const [contentItem, setContentItem] = useState<{
    title: string;
    content?: { __html: string };
  }>({ title: "" });
  useEffect(() => {
    const currentSlug = slug ? slug : "home";
    fetch(
      `https://wagtail.woodrisk.org/wagtail/api/v2/pages/?type=cms.ContentPage&slug=${currentSlug}&fields=*`
    )
      .then((r) => r.json())
      .then((r: any) => {
        if (r.items.length === 1) {
          setContentItem({
            title: r.items[0].title,
            content: { __html: r.items[0].body },
          });
        } else {
          navigate("/404");
        }
      });
  }, [slug, navigate]);

  return (
    <PageContainer
      style={{ alignItems: "center" }}
      title={"WWF Wood Risk Tool"}
    >
      <div
        style={{ width: "80%" }}
        dangerouslySetInnerHTML={contentItem.content}
      ></div>

      {/* Understand Risk */}
      <div
        style={{
          width: "100%",
          backgroundColor: "#154360",
          paddingTop: "2rem",
          paddingBottom: "6rem",
          marginTop: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href={"/understand-risk"}
            style={{
              backgroundColor: "#154360",
              width: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",

              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <div
              style={{ color: "white", marginTop: "2rem", textAlign: "center" }}
            >
              <h2 style={{ marginTop: "0", marginBottom: "2rem" }}>
                UNDERSTAND RISK
              </h2>
              <p>
                How forest- and timber-related risks are identified, gauged and
                communicated
              </p>
            </div>
            <div
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <img
                src={understandRiskImage1}
                alt={"looking upward at a green tree canopy"}
                style={{ width: "30vw" }}
              />
              <img
                src={understandRiskImage2}
                alt={"looking upward at a green tree canopy"}
                style={{ width: "30vw" }}
              />
              <img
                src={understandRiskImage3}
                alt={"looking upward at a green tree canopy"}
                style={{ width: "30vw" }}
              />
            </div>
          </a>
        </div>
      </div>

      {/* Assess Risk */}
      <div
        style={{
          width: "100%",
          backgroundColor: "#F1EBDD",
          paddingTop: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>ASSESS RISK</h2>
          <p style={{ width: "80%", textAlign: "center" }}>
            Information, insights, and risk scores for tree species and
            countries of origin.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "100%",
              flexWrap: "wrap",
              marginTop: "2rem",
              marginBottom: "5rem",
            }}
          >
            <a
              href={"/assess-risk/species"}
              className={"risk-button"}
            >
              <span
                role="img"
                aria-label={"a close up of tree rings"}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundImage: `url(${species_img})`,
                  backgroundBlendMode: "multiply",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <h3>SPECIES</h3>
              </span>
            </a>
            <a
              href={"/assess-risk/countries"}
              className={"risk-button"}
            >
              <span
                role="img"
                aria-label={
                  "an aerial shot where half of the shot is a lush fall forest with green and yellow leaves and the other half is deforested"
                }
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundImage: `url(${origin_img})`,
                  backgroundBlendMode: "multiply",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <h3>ORIGIN</h3>
              </span>
            </a>
          </div>
        </div>
      </div>

      {/* Manage Risk */}
      <a
        href={"/manage-risk"}
        style={{
          backgroundImage: `url(${manageRiskImage})`,
          textAlign: "center",
          width: "100%",
          height: "400px",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundSize: "cover",
          textDecoration: "none",
          backgroundBlendMode: "multiply",
          backgroundColor: "rgba(0,0,0,0.45)",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2 style={{ fontSize: "xxx-large", margin: 0 }}>MANAGE RISK</h2>
        <h3>WWF’s guidance on managing risk though responsible sourcing</h3>
      </a>
    </PageContainer>
  );
}

export default ContentPage;
